/**
 * Created by Andrey Popov on 07.04.2025
 */

var ScrollTableView = cleverapps.UI.ScrollView.extend({
    ctor: function (table, RowViewCtor) {
        var styles = cleverapps.styles.ScrollTableView;
        
        var container = this.container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(styles.size);

        this._super(container, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL,
            scrollBarEnabled: false,
            mouseScroll: false,
            clipping: false
        });
        this.touchScrollDisabled = true;
        
        this.table = table;
        this.RowViewCtor = RowViewCtor;

        this.setContentSize2(styles.size);        

        this.rowsViews = this.table.getRows().map(function (row) {
            return new RowViewCtor(row);
        });

        for (var i = 0; i < this.rowsViews.length; i++) {
            this.container.addChild(this.rowsViews[i]);
            this.rowsViews[i].setPosition(this.calculateRowPosition(this.rowsViews[i]));
        }

        this.updateSize();

        this.table.on("add", this.add.bind(this), this);
        this.table.on("remove", this.remove.bind(this), this);
        this.table.on("move", this.move.bind(this), this);
    },

    add: function (insertIndex) {
        var row = this.table.getRow(insertIndex);

        var rowView = new this.RowViewCtor(row);
        this.rowsViews.splice(insertIndex, 0, rowView);

        this.container.addChild(rowView);
        rowView.setPosition(this.calculateRowPosition(rowView));

        rowView.runAction(this.addRowAnimation(rowView));

        this.updateContainerSize();
    },

    addRowAnimation: function (rowView) {
        var styles = cleverapps.styles.ScrollTableView.animation;

        var targetPosition = rowView.getPosition();

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            rowView.setPositionRound(rowView.x, rowView.y - styles.moveDistanceY);

            return new cc.Sequence(
                new cc.DelayTime(2.4),
                new cc.Spawn(
                    new cc.MoveTo(0.15, targetPosition.x, targetPosition.y + styles.deltaY).easing(cc.easeOut(3)),
                    new cc.Sequence(
                        new cc.DelayTime(0.12),
                        new cc.ScaleTo(0.03, 1.1, 0.9).easing(cc.easeIn(1))
                    )
                ),
                new cc.Spawn(
                    new cc.MoveTo(0.15, targetPosition.x, targetPosition.y - styles.deltaY).easing(cc.easeOut(1)),
                    new cc.ScaleTo(0.15, 0.95, 1.05).easing(cc.easeOut(1))
                ),
                new cc.Spawn(
                    new cc.MoveTo(0.15, targetPosition).easing(cc.easeOut(1)),
                    new cc.ScaleTo(0.15, 1, 1).easing(cc.easeIn(1))
                )
            );
        }

        rowView.setPositionRound(-1.5 * this.getBoundingBoxToWorld().x - rowView.width / 2, rowView.y);

        return new cc.Sequence(
            new cc.DelayTime(2.4),
            new cc.Spawn(
                new cc.MoveTo(0.15, targetPosition.x + styles.deltaX, targetPosition.y).easing(cc.easeOut(3)),
                new cc.Sequence(
                    new cc.DelayTime(0.12),
                    new cc.ScaleTo(0.03, 0.9, 1.1).easing(cc.easeIn(1))
                )
            ),
            new cc.Spawn(
                new cc.MoveTo(0.15, targetPosition.x - styles.deltaX, targetPosition.y).easing(cc.easeOut(1)),
                new cc.ScaleTo(0.15, 1.05, 0.95).easing(cc.easeOut(1))
            ),
            new cc.Spawn(
                new cc.MoveTo(0.15, targetPosition).easing(cc.easeOut(1)),
                new cc.ScaleTo(0.15, 1, 1).easing(cc.easeIn(1))
            )
        );
    },

    remove: function (index) {
        var rowView = this.rowsViews[index];
        this.rowsViews.splice(this.rowsViews.indexOf(rowView), 1);

        rowView.runAction(this.rowRemoveAnimation(rowView));

        this.updateContainerSize();
    },

    rowRemoveAnimation: function (rowView) {
        var styles = cleverapps.styles.ScrollTableView.animation;

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            return new cc.Sequence(
                new cc.DelayTime(1.2),
                new cc.Spawn(
                    new cc.MoveBy(0.15, 0, styles.deltaY).easing(cc.easeIn(1)),
                    new cc.ScaleBy(0.15, 1.1, 0.9).easing(cc.easeIn(1))
                ),
                new cc.Spawn(
                    new cc.MoveBy(0.25, 0, -styles.moveDistanceY).easing(cc.easeIn(2)),
                    new cc.ScaleBy(0.1, 0.9, 1.1).easing(cc.easeIn(1))
                ),
                new cc.RemoveSelf()
            );
        }

        var endPos = cc.p(-1.5 * this.getBoundingBoxToWorld().x - rowView.width / 2, rowView.y);

        return new cc.Sequence(
            new cc.DelayTime(2.2),
            new cc.Spawn(
                new cc.MoveBy(0.15, styles.deltaX, 0).easing(cc.easeIn(1)),
                new cc.ScaleBy(0.15, 0.9, 1.1).easing(cc.easeIn(1))
            ),
            new cc.Spawn(
                new cc.MoveTo(0.25, endPos).easing(cc.easeIn(2)),
                new cc.ScaleBy(0.1, 1.1, 0.9).easing(cc.easeIn(1))
            ),
            new cc.RemoveSelf()
        );
    },

    move: function (index, direction) {
        var styles = cleverapps.styles.ScrollTableView;
        var margin = styles.margin[cleverapps.resolution.mode];
        var rowSize = styles.rowSize[cleverapps.resolution.mode];

        var delta = cc.p(0, 0);
        if (direction === "up" && index !== 0) {
            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                delta = cc.p(-rowSize.width - margin, 0);
            } else {
                delta = cc.p(0, rowSize.height + margin);
            }
        }

        var rowView = this.rowsViews[index];
        rowView.runAction(new cc.Sequence(
            new cc.DelayTime(2.4),
            new cc.MoveTo(0.5, cc.pAdd(rowView.getPosition(), delta)).easing(cc.easeBackInOut())
        ));
    },

    updateContainerSize: function () {
        var styles = cleverapps.styles.ScrollTableView;
        var isVert = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;

        var margin = styles.margin[cleverapps.resolution.mode];
        var rowSize = styles.rowSize[cleverapps.resolution.mode];

        var width;
        var height;

        var countOfRows = Math.max(this.table.minIconsCount, this.rowsViews.length);
        if (isVert) {
            height = rowSize.height;
            width = rowSize.width * countOfRows + (countOfRows - 1) * margin;
        } else {
            width = rowSize.width;
            height = rowSize.height * countOfRows + (countOfRows - 1) * margin;
        }

        var padding = isVert ? styles.padding.vertical : styles.padding;

        this.container.setContentSize2(width + 2 * padding.x, height + 2 * padding.y);
    },

    updateSize: function () {
        this.rowsViews.forEach(function (rowView) {
            rowView.stopAllActions();
            rowView.setPosition(this.calculateRowPosition(rowView));
        }.bind(this));

        this.updateContainerSize();

        var styles = cleverapps.styles.ScrollTableView;
        this.setContentSize2(styles.size);

        this.setContentSize(this.width, this.height);
        this.updateInnerContent();
    },

    calculateRowPosition: function (rowView) {
        var styles = cleverapps.styles.ScrollTableView;
        var isVert = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;

        var index = this.rowsViews.indexOf(rowView);
        if (!isVert) {
            index = Math.max(this.table.minIconsCount, this.rowsViews.length) - 1 - index;
        }

        var margin = styles.margin[cleverapps.resolution.mode];
        var rowSize = styles.rowSize[cleverapps.resolution.mode];
        var padding = isVert ? styles.padding.vertical : styles.padding;

        if (isVert) {
            return cc.p(padding.x + (margin + rowSize.width) * index + rowSize.width / 2, padding.y + rowSize.height / 2);
        }

        return cc.p(padding.x + rowSize.width / 2, padding.y + (margin + rowSize.height) * index + rowSize.height / 2);
    }
});

cleverapps.styles.ScrollTableView = {
    animation: {
        deltaX: 35,
        deltaY: 25,
        moveDistanceY: 300
    },

    size: [
        {
            width: 700,
            height: 160
        },
        {
            width: 500,
            height: 899
        },
        {
            width: 500,
            height: 899
        }
    ],

    rowSize: [
        {
            width: 210,
            height: 145
        },
        {
            width: 500,
            height: 213
        },
        {
            width: 500,
            height: 213
        }
    ],

    margin: [
        25,
        60,
        60
    ],

    padding: {
        x: 0,
        y: 30,

        vertical: {
            x: 10,
            y: 0
        }
    }
};
