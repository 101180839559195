/**
 * Created by Andrey Popov on 4/4/25.
 */

var ScrollTable = function (dataSource, options) {
    cleverapps.EventEmitter.call(this);

    this.minIconsCount = options.minIconsCount;
    this.addToStart = options.addToStart;

    this.dataSource = dataSource;

    this.rows = this.dataSource.getData().sort(function (a, b) {
        return b.score - a.score;
    });

    this.dataSource.onRowAdded = this.addRow.bind(this);
    this.dataSource.onRowRemoved = this.removeRow.bind(this);
};

ScrollTable.prototype = Object.create(cleverapps.EventEmitter.prototype);
ScrollTable.prototype.constructor = ScrollTable;

ScrollTable.prototype.getRows = function () {
    return this.rows;
};

ScrollTable.prototype.getRow = function (index) {
    if (index >= this.rows.length) {
        return undefined;
    }

    return this.rows[index];
};

ScrollTable.prototype.addRow = function (newRow) {
    var insertIndex = this.addToStart ? 0 : this.rows.length;
    this.rows.splice(insertIndex, 0, newRow);
    this.trigger("add", insertIndex);
};

ScrollTable.prototype.removeRow = function (rowId) {
    var row = this.rows.find(function (r) {
        return r.id === rowId;
    });
    var toRemoveIndex = this.rows.indexOf(row);

    this.rows.forEach(function (row, i) {
        if (i === toRemoveIndex) {
            return;
        }

        this.trigger("move", i, i < toRemoveIndex ? "down" : "up");
    }.bind(this));
    this.rows.splice(toRemoveIndex, 1);

    this.trigger("remove", toRemoveIndex);
};

ScrollTable.prototype.destructor = function () {
    this.dataSource.destructor();
};